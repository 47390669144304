// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

global = globalThis; // Need this for autocomplete.js to work with esbuild

require("@rails/ujs").start();
require("alpinejs");

const autocomplete = require("autocomplete.js");
const axios = require("axios");

autocomplete(
  "#search-input",
  { hint: false, autoselect: true, autoselectOnBlur: true },
  [
    {
      source: function (query, cb) {
        axios.get(`/cities?q=${query}`).then(function (response) {
          cb(response.data);
        });
      },
      displayKey: "name",
      templates: {
        suggestion: function (suggestion) {
          return suggestion.name;
        },
      },
    },
  ]
).on("autocomplete:selected", function (event, suggestion) {
  document.querySelector("#city_id").value = suggestion.id;
});

window.recommendations = () => {
  return {
    answered: false,
    voteValue: null,
    show: true,
    init($watch, $el) {
      $watch("voteValue", () => {
        this.answered = true;

        const recommendation_url = $el.getAttribute("data-recommendation-url");

        axios.post(recommendation_url, {
          voteValue: this.voteValue,
        });
      });
    },
  };
};
